import React, { FC } from "react";

import type { SvgIconConstituentValues } from "./typed";

export const СapaIcon: FC<SvgIconConstituentValues> = () => (
  <svg
    width="24"
    height="22"
    viewBox="0 0 24 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.1733 19.8265C13.1733 20.1509 12.9094 20.4148 12.5851 20.4148H11.4086C11.0842 20.4148 10.8203 20.1509 10.8203 19.8265V19.2383H13.1733V19.8265Z"
      stroke="black"
      strokeWidth="0.75"
      strokeMiterlimit="10"
    />
    <path
      d="M14.8747 14.0329C14.151 14.7581 13.7359 15.799 13.7359 16.8885V17.0158C13.7359 17.3369 13.4746 17.5982 13.1536 17.5982H10.8241C10.503 17.5982 10.2417 17.3369 10.2417 17.0158V16.8908C10.2417 15.7843 9.8383 14.7539 9.13479 14.0643C8.09079 13.0405 7.68236 11.5605 8.04226 10.105C8.39635 8.67195 9.5576 7.52894 10.9999 7.19272C13.666 6.57113 16.0654 8.56129 16.0654 11.1536C16.0654 12.2407 15.6426 13.2634 14.8747 14.0333V14.0329Z"
      stroke="black"
      strokeWidth="0.75"
      strokeMiterlimit="10"
    />
    <path
      d="M11.9291 9.53125C11.9291 9.53125 9.81549 9.69043 9.79297 11.7357"
      stroke="black"
      strokeWidth="0.75"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M5.25781 4.49609L7.04376 6.28204"
      stroke="black"
      strokeWidth="0.75"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M18.7039 4.49609L16.918 6.28204"
      stroke="black"
      strokeWidth="0.75"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M11.9805 2.07422V4.68946"
      stroke="black"
      strokeWidth="0.75"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M2.44141 11.0898H5.05665"
      stroke="black"
      strokeWidth="0.75"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M18.9414 11.0898H21.5566"
      stroke="black"
      strokeWidth="0.75"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
  </svg>
);
