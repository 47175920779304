import { createApi } from "@reduxjs/toolkit/query/react";

import { endpoints } from "constant";
import {
  AuthUserClient,
  ICollaboratorClient,
  ICreateNewPassword,
  IGlobalPartnerClient,
  LoginRequest,
} from "types";

import { axiosBaseQuery } from "utils/baseQuery";
import { clientUrlBuilder } from "./urlBuilder/clientUrlBuilder";

export const authUserClientAPI = createApi({
  reducerPath: "authUserClientAPI",
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
  }),

  tagTypes: ["authUserClientAPI"],
  endpoints: build => ({
    loginClients: build.mutation<AuthUserClient, LoginRequest>({
      query: body => ({
        url: endpoints.clientPortalLogin,
        method: "POST",
        data: {
          email: body.email,
          password: body.password,
          remember_me: body.rememberMe,
          recaptcha: body.reCaptcha,
        },
      }),
      invalidatesTags: ["authUserClientAPI"],
      transformResponse: (response: AuthUserClient) => response,
    }),
    passwordReset: build.query<AuthUserClient, string>({
      query: email => ({
        url: clientUrlBuilder.resetPassword(),
        method: "POST",
        data: {
          email,
        },
      }),
    }),
    createNewPassword: build.query<AuthUserClient, ICreateNewPassword>({
      query: ({ password, token }) => ({
        url: clientUrlBuilder.createNewPassword(),
        method: "POST",
        data: {
          password,
          token,
        },
      }),
    }),
    updatePassword: build.query<void, ICreateNewPassword>({
      query: ({ password, token }) => ({
        url: clientUrlBuilder.updatePassword(),
        method: "PUT",
        data: {
          password,
        },
        headers: { authorization: token },
      }),
    }),
    validateToken: build.query<void, { token: string }>({
      query: ({ token }) => ({
        url: clientUrlBuilder.validateToken(),
        method: "POST",
        data: {
          token,
        },
      }),
    }),
    getCollaboratorClients: build.query<
      ICollaboratorClient[],
      { token: string }
    >({
      query: ({ token }) => ({
        url: clientUrlBuilder.getCollaboratorClients(),
        method: "GET",
        headers: { authorization: token },
      }),
    }),
    setCollaboratorClient: build.query<
      void,
      { id: number | string; token: string }
    >({
      query: ({ id, token }) => ({
        url: clientUrlBuilder.setCollaboratorClients(id),
        method: "PATCH",
        headers: { authorization: token },
      }),
    }),

    getGlobalPartnerClients: build.query<
      IGlobalPartnerClient[],
      { token: string }
    >({
      query: ({ token }) => ({
        url: clientUrlBuilder.getGlobalPartnerClients(),
        method: "GET",
        headers: { authorization: token },
      }),
    }),
    setGlobalPartnerClient: build.query<
      void,
      { id: number | string; token: string }
    >({
      query: ({ id, token }) => ({
        url: clientUrlBuilder.setGlobalPartnerClients(id),
        method: "PATCH",
        headers: { authorization: token },
      }),
    }),
  }),
});

export const {
  useLoginClientsMutation,
  useLazyPasswordResetQuery,
  useLazyCreateNewPasswordQuery,
  useLazyUpdatePasswordQuery,
  useLazyValidateTokenQuery,
  useGetCollaboratorClientsQuery,
  useLazySetCollaboratorClientQuery,
  useGetGlobalPartnerClientsQuery,
  useLazySetGlobalPartnerClientQuery,
} = authUserClientAPI;
