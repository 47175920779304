import React, { FC } from "react";

import { TabPanel } from "./TabPanel";
import { Box, MUITabs, Tab } from "./styled";
import type { TabsProps } from "./typed";

function getTabProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export const Tabs: FC<TabsProps> = ({
  tabs,
  variant,
  scrollButtons,
  visual,
  paddingHorizontal,
  customWidth,
}) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  return (
    <Box>
      <MUITabs
        value={value}
        onChange={handleChange}
        variant={variant}
        scrollButtons={scrollButtons}
      >
        {tabs.map(({ label, index, TabComponent, Icon }) =>
          TabComponent ? (
            <TabComponent key={index} />
          ) : (
            <Tab
              visual={visual}
              key={index}
              label={label}
              icon={Icon}
              paddingHorizontal={paddingHorizontal}
              customWidth={customWidth}
              {...getTabProps(index)}
            />
          ),
        )}
      </MUITabs>
      {tabs.map(({ index, Component }) => (
        <TabPanel key={index} value={value} index={index}>
          {Component}
        </TabPanel>
      ))}
    </Box>
  );
};
