import React, { FC } from "react";
import { SvgIconConstituentValues } from "./typed";

export const BriefCaseIcon: FC<SvgIconConstituentValues> = () => (
  <svg
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.309 19.5042C21.309 19.9033 20.9854 20.2268 20.5863 20.2268H5.41017C5.01105 20.2268 4.6875 19.9033 4.6875 19.5042V9.38674C4.6875 8.98761 5.01105 8.66406 5.41017 8.66406H20.5863C20.9854 8.66406 21.309 8.98761 21.309 9.38674V19.5042Z"
      stroke="black"
    />
    <path
      d="M10.1094 8.66413L10.4707 6.13477C10.4707 5.93521 10.6325 5.77344 10.832 5.77344H15.1681C15.3677 5.77344 15.5294 5.93521 15.5294 6.13477L15.8908 8.66413"
      stroke="black"
    />
    <path d="M7.57812 11.5547H18.4182" stroke="black" />
    <path d="M7.57812 14.4453H18.4182" stroke="black" />
    <path d="M7.57812 17.3359H18.4182" stroke="black" />
  </svg>
);
